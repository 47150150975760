<template>
  <div>
    <slot :status="moduleStatus" />
    <b-card v-if="!moduleStatus">
      <b-row class="mb-2 justify-content-end">
        <slot name="export" />

        <bulk-actions
          v-if="selectable"
          ref="actions"
          :api-url="apiUrl"
          :add-type="addType"
          :bulk-delete="bulkDelete"
          :go-to-page-or-open-modal="goToPageOrOpenModal"
          :guard="guard"
          :selected="selected"
        >
          <template
            v-if="exportTable"
            v-slot:exportbtn
          >
            <b-button
              v-if="!loadExport"
              variant="warning"
              class="btn-icon mx-1"
              @click="exportToCSV"
            >
              <feather-icon icon="ExternalLinkIcon" />
              Export
            </b-button>
            <b-button
              v-else
              variant="warning"
              class="btn-icon mx-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Exporting...
            </b-button>
          </template>
          <template v-slot:addbtn>
            <b-button
              v-if="addType.length > 0 && $can('create', guard)"
              variant="primary"
              @click="goToPageOrOpenModal()"
            >
              <span class="text-nowrap"> {{ addType }} </span>
            </b-button>
          </template>
        </bulk-actions>

        <b-button
          v-if="!selectable && addType.length > 0 && !addMultiType && $can('create', guard)"
          ref="actions"
          variant="primary"
          class="ml-auto mr-1"
          @click="goToPageOrOpenModal()"
        >
          <span class="text-nowrap"> {{ addType }} </span>
        </b-button>
        <slot name="import" />

        <b-dropdown
          v-if="!selectable && addType.length > 0 && addMultiType && $can('create', guard)"

          :text="addType"
          variant="primary"
          class="m-2"
        >
          <b-dropdown-item @click="goToPageForTypes(addTypeOnePage)">
            {{ addTypeOne }}
          </b-dropdown-item>
          <b-dropdown-item @click="goToPageForTypes(addTypeTwoPage)">
            {{ addTypeTwo }}

          </b-dropdown-item>
        </b-dropdown>
      </b-row>
      <b-row class="d-flex align-items-center justify-content-start mb-2">
        <b-col
          v-if="searchBox"
          cols="12"
          md="4"
        >
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Search..."
            @input="searchTable"
          />
        </b-col>
        <slot name="searchByPhone" />
        <slot name="filterUsers" />
        <slot name="filterBillers" />
        <slot name="filterServices" />

      </b-row>
      <b-row>
        <b-col
          md="12"
        >
          <div
            v-if="dataLoad"
            class=" text-center my-2"
          >
            <b-spinner
              type="grow"
              label="Loading..."
            />
          </div>
          <b-table
            v-else
            ref="productsTable"
            :items="rows"
            :fields="columns"
            :current-page="currentPage"
            :per-page="perPage"
            aria-busy="true"
            class="position-relative"
            responsive
            show-empty
            bordered
            primary-key="id"
            :selectable="selectable"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :select-mode="selectMode"
            :no-local-sorting="true"
            @row-selected="onRowSelected"
            @sort-changed="sortData"
          >
            <template
              v-for="(_, slotName) of $scopedSlots"
              v-slot:[slotName]="scope"
            >
              <slot
                :name="slotName"
                v-bind="scope"
              />
            </template>
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template v-slot:head(select)="data">
              <span v-if="selected.length < 1"> {{ data.field.label }} </span>
              <b-form-checkbox
                v-else
                class="custom-control-primary"
                @change="selectAllRows($event)"
              />
            </template>
            <template #cell(select)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <b-form-checkbox
                  class="custom-control-primary"
                  disabled
                />
              </template>
            </template>
            <template
              v-slot:cell(image)="value"
            >
              <b-img
                v-img
                width="70"
                :src="value.item.image"
              /></template>
            <template #cell(actions)="data">
              <slot
                name="actions"
                v-bind="data"
              >
                <div class="padd">
                  <span
                    v-if="viewContent && $can('read', guard)"
                    title="View"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="viewPage(data.item)"
                    />
                  </span>
                  <span
                    v-if="editContent && $can('update', guard)"
                    title="Edit"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="cursor-pointer"
                      @click="editToPage(data.item.id)"
                    />
                  </span>
                  <span
                    v-if="deleteContent && $can('delete', guard)"
                    title="Delete"
                  >
                    <feather-icon
                      id="popover-button-1"
                      icon="TrashIcon"
                      class="cursor-pointer"
                      @click="deleteRow(data.item.id)"
                    />
                  </span>
                </div>
              </slot>
            </template>
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template
                  v-if="data.item.profile_picture"
                  #aside
                >
                  <b-avatar
                    size="40"
                    :text="avatarText(data.item.first_name)"
                    :src="data.item.profile_picture"
                  />
                </template>

                <template
                  v-else-if="data.item.logo"
                  #aside
                >
                  <b-avatar
                    size="32"
                    :src="data.item.logo"
                  />
                </template>
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  @click="viewPage(data.item)"
                >
                  {{ data.item.name }}
                </b-link>
                <small
                  v-if="data.item.username"
                  class="text-muted"
                >@{{ data.item.username }}</small>
                <small
                  v-if="data.item.name_ar"
                  class="text-muted"
                >{{ data.item.name_ar }}</small>
                <small
                  v-if="data.item.national_id"
                  class="text-muted"
                >{{ data.item.national_id }}</small>
              </b-media>
            </template>
            <template #cell(activation_at)="data">
              {{ data.item.activation_at.slice(0, 10) }}
            </template>
            <template #cell(is_active)="data">
              <b-badge
                class="change-status"
                :variant="data.item.is_active === true || data.item.is_active === 1 ? 'success' : 'danger'"
                @click="changeSchangeActivetatus(data.item)"
              >
                {{ resolveActiveUser(data.item.is_active) }}
              </b-badge>
            </template>
            <template #cell(active)="data">
              <b-badge
                class="change-status"
                :variant="data.item.active == true ? 'success' : 'danger'"
                @click="changeStatus(data.item)"
              >
                {{ resolveActiveUser(data.item.active) }}
              </b-badge>
            </template>
            <!-- <template #cell(status)="data">
              <b-badge :variant="data.item.status == 'true' ? 'success' : 'danger'">
                {{ resolveActiveOrFalse(data.item.status) }}
              </b-badge>
            </template> -->
            <template #cell(role)="data">
              {{ resolveJobStatus(data.item.role) }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="getAllDataPerPage"
            />
            <label>entries</label>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <!-- pagination -->
            <b-pagination
              v-if="totalRows > 0"
              v-model="page"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              first-number
              last-number
              class="mb-0 mt-1"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="getAllData($event)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import axios from 'axios'
import { avatarText } from '@core/utils/filter'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import handleFormData from '@/global-compositions/handleFormData'
import BulkActions from './BulkActions.vue'
import table from './table'

export default {
  components: {
    BulkActions,
    vSelect,
  },
  props: {
    apiUrl: {
      type: String,
      default: () => '',
    },
    addType: {
      type: String,
      default: () => '',
    },
    addTypeOne: {
      type: String,
      default: () => '',
    },
    addTypeTwo: {
      type: String,
      default: () => '',
    },
    addComponentName: {
      type: String,
      default: () => '',
    },
    addTypeOnePage: {
      type: String,
      default: () => '',
    },
    addTypeTwoPage: {
      type: String,
      default: () => '',
    },
    editComponent: {
      type: String,
      default: () => '',
    },
    viwComponent: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    viewContent: {
      type: Boolean,
      default: () => true,
    },
    editContent: {
      type: Boolean,
      default: () => true,
    },
    deleteContent: {
      type: Boolean,
      default: () => true,
    },
    searchBox: {
      type: Boolean,
      default: () => true,
    },
    selectable: {
      type: Boolean,
      default: () => true,
    },
    guard: {
      type: String,
      default: () => '',
    },
    role: {
      type: [String, Number],
      default: () => '',
    },
    exportTable: {
      type: Boolean,
      default: () => true,
    },
    addMultiType: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      rows: [],
      totalRows: null,
      isBusy: false,
      currentPage: 1,
      perPage: 15,
      searchQuery: '',
      popoverShow: false,
      loader: false,
      currentTime: '',
      showEmpty: 'loading...',
      modes: ['multi', 'single', 'range'],
      selectMode: 'multi',
      selected: [],
      page: 1,
      sortBy: '',
      sortDesc: '',
      loadExport: false,
      dataLoad: 'false',
    }
  },
  setup() {
    const { moduleStatus } = table()
    const resolveActiveUser = role => {
      if (role === true || role === 1) return 'Active'

      return 'Not Active'
    }
    const { formData, setFormData } = handleFormData()
    const { skin } = useAppConfig()
    const resolveActive = role => {
      if (role === '1') return 'Active'

      return 'Not Active'
    }
    const resolveJobStatus = role => {
      if (role === 0) return 'Admin'
      if (role === 1) return 'Merchant'

      return 'Customer'
    }

    const resolveActiveOrFalse = role => {
      // eslint-disable-next-line eqeqeq
      if (role == 'true') return 'Active'

      return 'Not Active'
    }

    const perPageOptions = [10, 25, 50, 100]

    return {
      resolveActiveUser,
      resolveActive,
      resolveActiveOrFalse,
      resolveJobStatus,
      skin,
      formData,
      setFormData,
      avatarText,
      perPageOptions,
      moduleStatus,
    }
  },
  mounted() {
    // eslint-disable-next-line radix
    const currentPage = parseInt(this.$route.query.page) || 1
    this.page = currentPage
    this.getAllData(this.page)
    this.getRouting()
    this.getCurrentDateTime()
  },
  methods: {
    exportToPDF() {
      const self = this
      this.loadExport = true
      const promis = axios.get(this.apiUrl, { params: { limit: self.perPage, export: 'pdf' }, responseType: 'arraybuffer' })
      return promis.then(res => {
        if (res.status === 200) {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'exported.pdf'
          link.click()
        }
      }).finally(() => {
        this.loadExport = false
      })
    },
    exportToCSV() {
      this.loadExport = true
      const self = this
      const promis = axios.get(this.apiUrl, { params: { limit: self.perPage, export: 'csv' }, responseType: 'arraybuffer' })
      return promis.then(res => {
        if (res.status === 200) {
          const blob = new Blob([res.data], { type: 'application/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'exported.csv'
          link.click()
        }
      }).finally(() => {
        this.loadExport = false
      })
    },
    onRowSelected(items) {
      const rows = []
      // eslint-disable-next-line array-callback-return
      items.map(item => {
        rows.push(item.id)
      })
      this.selected = rows
    },
    selectAllRows(rows) {
      if (rows) {
        this.$refs.productsTable.selectAllRows()
      } else {
        this.$refs.productsTable.clearSelected()
      }
    },
    onClose() {
      this.popoverShow = false
    },
    showPopover() {
      this.popoverShow = true
    },
    changeStatus(data) {
      this.$swal({
        title: 'Are you sure that you want to change status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change it!',
      }).then(result => {
        if (result.isConfirmed) {
          if (data.active === false) {
            this.setFormData(data)
            this.formData.append('activation_at', this.currentTime)
            this.formData.append('_method', 'PUT')
            axios.post(`${this.apiUrl}/${data.id}`, this.formData).then(res => {
              if (res.status === 200) {
                this.$swal(
                  'Changed!',
                  'Changed Successfully.',
                  'success',
                )
                this.getAllData()
              }
            })
          } else {
            this.setFormData(data)
            this.formData.append('activation_at', '')
            this.formData.append('_method', 'PUT')
            axios.post(`${this.apiUrl}/${data.id}`, this.formData).then(res => {
              if (res.status === 200) {
                this.$swal(
                  'Changed!',
                  'Changed Successfully.',
                  'success',
                )
                this.getAllData()
              }
            })
          }
        }
      })
    },
    changeSchangeActivetatus(data) {
      this.$swal({
        title: 'Are you sure that you want to change status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change it!',
      }).then(result => {
        if (result.isConfirmed) {
          if (data.is_active === false || data.is_active === 0) {
            this.setFormData(data)
            this.formData.append('is_active', 1)
            if (data.expire_at) {
              this.formData.append('expire_at', data.expire_at.slice(0, 10))
            }
            this.formData.append('_method', 'PUT')
            axios.post(`${this.apiUrl}/${data.id}`, this.formData).then(res => {
              if (res.status === 200) {
                this.$swal(
                  'Changed!',
                  'Changed Successfully.',
                  'success',
                )
                this.getAllData()
              }
            })
          } else {
            this.setFormData(data)
            this.formData.append('is_active', 0)
            if (data.expire_at) {
              this.formData.append('expire_at', data.expire_at.slice(0, 10))
            }
            this.formData.append('_method', 'PUT')
            axios.post(`${this.apiUrl}/${data.id}`, this.formData).then(res => {
              if (res.status === 200) {
                this.$swal(
                  'Changed!',
                  'Changed Successfully.',
                  'success',
                )
                this.getAllData()
              }
            })
          }
        }
      })
    },
    editToPage(id) {
      if (this.type === 'page') {
        this.$router.push({ name: this.editComponent, params: { id } })
      } else {
        this.$parent.$refs.genmodal.show(id)
        this.$store.commit('generalIds/SET_ID', id)
      }
    },
    viewPage(data) {
      if (this.viwComponent) {
        this.$router.push({ name: this.viwComponent, params: { id: data.id } })
        this.$store.commit('generalIds/SET_ID', data.id)
      } else {
        this.$parent.$refs['view-modal'].show()
        this.$store.commit('generalIds/SET_ID', data.id)
      }
    },
    goToPageOrOpenModal() {
      if (this.type === 'page') {
        this.$router.push({ name: this.addComponentName })
      } else {
        this.$parent.$refs.genmodal.show()
      }
    },
    goToPageForTypes(componentName) {
      this.$router.push({ name: componentName })
    },
    roleType() {
      if (this.$route.name === 'users') {
        return '0'
      } if (this.$route.name === 'merchant-users') {
        return '1'
      } if (this.$route.name === 'customer-users') {
        return '2'
      }
      return ''
    },
    getAllData(page) {
      this.dataLoad = true
      const self = this
      const sortType = () => {
        if (this.sortDesc) return 'DESC'
        return 'ASC'
      }
      const promis = axios.get(this.apiUrl,
        {
          params: {
            limit: self.perPage,
            search: self.searchQuery || self.$route.query.search,
            page,
            sort_colum: self.sortBy,
            sort_type: sortType(),
            role: self.roleType(),
          },
        })
      return promis.then(res => {
        if (res.status === 200) {
          const items = res.data?.data
          this.rows = items
          this.totalRows = res.data.meta?.total
          this.perPage = res.data.meta?.per_page
          this.$router.replace({ query: { ...this.$route.query, page } }).catch(() => {})
          this.page = page
          // this.getRouting()
        }
      }).catch(error => {
        if (error.response.status === 500) {
          if (this.$route.fullPath.includes('offer')) {
            this.moduleStatus = true
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }).finally(() => {
        this.loader = false
        this.dataLoad = false
      })
    },
    deleteRow(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios.delete(`${this.apiUrl}/${id}`).then(() => {
            this.$swal(
              'Deleted!',
              'Deleted Successfully.',
              'success',
            )
            this.getAllData()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    async searchTable() {
      if (this.apiUrl.includes('list_orders')) {
        const response = await axios.get(`${this.apiUrl}?phone=${this.searchQuery}&page=${this.$route.query?.page || 1}&role=${this.roleType()}`)
        this.$router.push({ query: { ...this.$route.query, search: this.searchQuery } }).catch(() => {})
        this.rows = response.data.data
        this.totalRows = response.data.meta?.total
        this.perPage = response.data.meta?.per_page
        this.page = response.data.meta?.per_page
      } else {
        const response = await axios.get(`${this.apiUrl}?search=${this.searchQuery}&page=${this.$route.query?.page || 1}&role=${this.roleType()}`)
        this.$router.push({ query: { ...this.$route.query, search: this.searchQuery } }).catch(() => {})
        this.rows = response.data.data
        this.totalRows = response.data.meta?.total
        this.perPage = response.data.meta?.per_page
        this.page = response.data.meta?.per_page
      }
    },
    getAllDataPerPage(perpage) {
      const self = this
      const promis = axios.get(this.apiUrl,
        {
          params: {
            limit: perpage,
            role: self.roleType(),
          },
        })
      return promis.then(res => {
        if (res.status === 200) {
          const items = res.data?.data
          this.rows = items
          this.totalRows = res.data.meta?.total
          this.perPage = res.data.meta?.per_page
        }
      })
    },
    getRouting() {
      if (this.$route.query.search) {
        this.searchQuery = this.$route.query?.search
        this.searchTable()
      }
    },
    getCurrentDateTime() {
      const today = new Date()

      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`

      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`

      const dateTime = `${date} ${time}`

      this.currentTime = dateTime
    },
    bulkDelete() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios.post(`${this.apiUrl}/bulk-destroy`, {
            ids: this.selected,
          }).then(() => {
            this.$swal(
              'Deleted!',
              'Deleted Successfully.',
              'success',
            )
            this.getAllData()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    sortData(e) {
      const self = this
      const sortType = () => {
        if (e.sortDesc) return 'DESC'
        return 'ASC'
      }
      const promis = axios.get(this.apiUrl,
        {
          params: {
            limit: self.perPage,
            search: self.searchQuery || self.$route.query.search,
            sort_colum: e.sortBy,
            sort_type: sortType(),
          },
        })
      return promis.then(res => {
        if (res.status === 200) {
          const items = res.data?.data
          this.rows = items
          this.totalRows = res.data.meta?.total
          this.perPage = res.data.meta?.per_page
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },
}
</script>

<style lang="scss">
.padd span {
  padding: 2px;
}
#table-spinner {
    position: absolute;
    right: 50%;
    top: 5rem;
    z-index: 999999999
}
.change-status {
  cursor: pointer;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
